// FirebaseManager.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, GoogleAuthProvider, signInWithPopup, signOut, linkWithPopup} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, getDocs, serverTimestamp, query, where, orderBy, onSnapshot, deleteDoc, limit, startAfter,endBefore  } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL,deleteObject ,getMetadata ,listAll } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { CommonUtils } from './CommonUtils';
//import { UserIcon } from './UserIcon';

const firebaseConfig = {
	apiKey: "AIzaSyAh16oKcpXld0I4F_cQZ9Ovt0MOJLV8Mq0",
	authDomain: "collabomap-1bcb9.firebaseapp.com",
	projectId: "collabomap-1bcb9",
	storageBucket: "collabomap-1bcb9.appspot.com",
	messagingSenderId: "235227924874",
	appId: "1:235227924874:web:98bd908ed1741fd7fb2073",
	measurementId: "G-8CJ6RSTND5"
};

const app = initializeApp(firebaseConfig);


class FirebaseManager {

	constructor() {
		this.auth = getAuth(app);
		this.firestore = getFirestore(app);
		this.storage = getStorage(app);
		this._myUid = null;
		this._myColor = 0;
		this._myName = "";
		this._myIconBlob = null;
		this._userIcons = [];
		this._userIconUrls = [];
		this.analytics = null;
	}

	getIcon(uid,iconurl) {
		if(!this._userIcons[uid]) {
		
		}
		else {
			return this._userIcons[uid];
		}
		return null;
	}
	getIconUrl(uid) {
		return this._userIconUrls[uid];
	}


	// Firebase Analyticsの初期化
	initializeAnalytics() {
		this.analytics = getAnalytics(app);
	}
  // イベントをログに記録
	logEvent(eventName, eventParams = {}) {
		if (this.analytics) {
			logEvent(this.analytics, eventName, eventParams);
		} else {
			console.warn('Analytics not initialized. Call initializeAnalytics() first.');
		}
	}


	// アカウント永続化メソッド
	async linkAnonymousAccountWithGoogle() {
		if (!this.auth.currentUser) {
			throw new Error('No user is signed in');
		}

		if (!this.auth.currentUser.isAnonymous) {
			throw new Error('Current user is not anonymous');
		}

		try {
			const provider = new GoogleAuthProvider();
			const result = await linkWithPopup(this.auth.currentUser, provider);
			
			// リンク成功後の処理
			const user = result.user;
			console.log('Anonymous account successfully upgraded', user);
			
			// ユーザー情報を更新
			await this.updateUserAfterLinking(user);

			return user;
		} catch (error) {
			console.error('Error linking anonymous account:', error);
			throw error;
		}
	}

	// ユーザー情報更新メソッド
	async updateUserAfterLinking(user) {
		const uid = user.uid;
		const userDocRef = doc(this.firestore, 'users', uid);

		try {
			const userDoc = await getDoc(userDocRef);
			if (userDoc.exists()) {
				// 既存のユーザーデータを更新
				await updateDoc(userDocRef, {
					email: user.email,
					displayName: user.displayName,
					photoURL: user.photoURL,
					isAnonymous: false,
					updatetime: new Date()
				});
			} else {
				// 新しいユーザードキュメントを作成
				await setDoc(userDocRef, {
					email: user.email,
					displayName: user.displayName,
					photoURL: user.photoURL,
					isAnonymous: false,
					createtime: new Date(),
					updatetime: new Date()
				});
			}
			console.log('User document updated after linking');
		} catch (error) {
			console.error('Error updating user document after linking:', error);
			throw error;
		}
	}
	setMyUid(uid) {
		this._myUid = uid;
	}
	setNameAndColor(name,color) {
		this._myColor = color;
		this._myName = name;
	}

	// 匿名ログイン
	async signInAnonymously() {
		try {
			const userCredential = await signInAnonymously(this.auth);
			return userCredential.user;
		} catch (error) {
			console.error('Anonymous sign-in error:', error);
			throw error;
		}
	}

	// Googleログイン
	async signInWithGoogle() {
		const provider = new GoogleAuthProvider();
		try {
		const userCredential = await signInWithPopup(this.auth, provider);
		this.logEvent('google_sign_in');
		return userCredential.user;
		} catch (error) {
		console.error('Google sign-in error:', error);
		this.logEvent('google_sign_in_error', { error_message: error.message });
		throw error;
		}
	}

	// サインアウト
	async signOut() {
		try {
		await signOut(this.auth);
		this.logEvent('user_sign_out');
		} catch (error) {
		console.error('Sign-out error:', error);
		this.logEvent('sign_out_error', { error_message: error.message });
		throw error;
		}
	}


	// Firestoreのデータ読み取り
	async getDocument(collection, docId) {
		try {
			const docRef = doc(this.firestore, collection, docId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			} else {
				throw new Error('No such document!');
			}
		} catch (error) {
			console.error('Error getting document:', error);
			throw error;
		}
	}

	// Firestoreのデータ書き込み
	async setDocument(collection, docId, data) {
		try {
			const docRef = doc(this.firestore, collection, docId);
			await setDoc(docRef, data);
		} catch (error) {
			console.error('Error setting document:', error);
			throw error;
		}
	}

	// Firestoreのデータ更新
	async updateDocument(collection, docId, data) {
		try {
			const docRef = doc(this.firestore, collection, docId);
			await updateDoc(docRef, data);
		} catch (error) {
			console.error('Error updating document:', error);
			//throw error;
		}
	}

  // ドキュメントが存在するか確認するメソッド
	async documentExists(collection, docId) {
		try {
			const docRef = doc(this.firestore, collection, docId);
			const docSnap = await getDoc(docRef);
			return docSnap.exists();
		} catch (error) {
			console.error('Error checking document existence:', error);
			throw error;
		}
	}

	//ファイルコピー
	async copyFile(sourcePath, destinationPath) {
		try {
			const sourceRef = ref(this.storage, sourcePath);
			
			// ソースファイルのメタデータを取得
			const metadata = await getMetadata(sourceRef);
			
			const url = await getDownloadURL(sourceRef);
			const response = await fetch(url);
			const blob = await response.blob();
			const destinationRef = ref(this.storage, destinationPath);

			// メタデータを使用してファイルをアップロード
			const snapshot = await uploadBytes(destinationRef, blob, metadata);
			const downloadURL = await getDownloadURL(snapshot.ref);

//			console.log('ファイルが正常にコピーされました。コピー元:', sourcePath, 'コピー先:', destinationPath);
			return downloadURL;
		} catch (error) {
//			console.error('ファイルのコピー中にエラーが発生しました:', error);
			throw error;
		}
	}

	// Firebase Storageにファイルをアップロード
	async uploadFile(storagePath, file ,fileType) {
		try {
			const storageRef = ref(this.storage, storagePath);
			const metadata = {
				contentType: fileType, // ファイルのContent-Typeを設定
			};
			const snapshot = await uploadBytes(storageRef, file, metadata);
			const downloadURL = await getDownloadURL(snapshot.ref);
			return downloadURL;
		} catch (error) {
			console.error('Error uploading file:', error);
			throw error;
		}
	}


	// Firebase Storageからファイルをダウンロード
	async downloadFile(storagePath) {
		try {
			const storageRef = ref(this.storage, storagePath);
			const url = await getDownloadURL(storageRef);
			return url;
		} catch (error) {
			console.error('Error downloading file:', error);
			throw error;
		}
	}

	async getFirebaseStorageUrl(storagePath){
		try {
			const storage = getStorage();
			const fileRef = ref(storage, storagePath);
			const url = await getDownloadURL(fileRef);
			return url;
		} catch (error) {
			console.error("Error getting download URL: ", error);
			return null;
		}
	};


	async deleteFile(storagePath) {
		try {
			const fileRef = ref(this.storage, storagePath);
			
			// ファイルの存在を確認
			try {
				await getMetadata(fileRef);
			} catch (error) {
			// ファイルが存在しない場合、ここでキャッチされる
			console.log(`File does not exist at path: ${storagePath}`);
			return; // ファイルが存在しない場合は早期リターン
			}

			// ファイルが存在する場合、削除を実行
			await deleteObject(fileRef);
			console.log(`File successfully deleted at path: ${storagePath}`);
		} catch (error) {
			console.error(`Error deleting file at path: ${storagePath}`, error);
		}
	}
	
	// Firestoreのコレクションを取得
	async getCollection(collectionPath) {
		try {
			const querySnapshot = await getDocs(collection(this.firestore, collectionPath));
			return querySnapshot;
		} catch (error) {
			console.error('Error getting collection:', error);
			throw error;
		}
	}

	// Firestoreのドキュメントのリアルタイムリスナー
	subscribeToDocument(docPath, callback) {
		const docRef = doc(this.firestore, docPath);
		return onSnapshot(docRef, callback);
	}

	// Firestoreのコレクションのリアルタイムリスナー
	subscribeToCollection(collectionPath, callback) {
		const q = query(collection(this.firestore, collectionPath), orderBy('updatetime', 'desc'));
		return onSnapshot(q, callback);
	}

	subscribeToCollectionWithUpdateTime(collectionPath,currentTime, callback) {
		const q = query(collection(this.firestore, collectionPath), where('updatetime', '>=', currentTime),orderBy('updatetime', 'desc'));
		return onSnapshot(q, callback);
	}

	// Firestoreのコレクションのリアルタイムリスナー（修正版）
	subscribeToCollectionWithOption(collectionPath, options, callback) {
		const { limit: limitCount = 10, orderBy: orderByField = 'updatetime', orderDirection = 'desc', where: whereConditions = [] } = options;
		
		let q = query(collection(this.firestore, collectionPath));
		
		if (Array.isArray(whereConditions) && whereConditions.length === 3) {
			q = query(q, where(whereConditions[0], whereConditions[1], whereConditions[2]));
		} 

		if (Array.isArray(orderByField)) {
			orderByField.forEach(field => {
				q = query(q, orderBy(field, orderDirection));
			});
		} else {
			q = query(q, orderBy(orderByField, orderDirection));
		}
		
		q = query(q, limit(limitCount));
		
		return onSnapshot(q, callback);
	}

	async getMoreDocuments(collectionPath, options) {
		const { 
			limit: limitCount = 10, 
			orderBy: orderByField = 'updatetime', 
			orderDirection = 'desc', 
			where: whereConditions = [],
			endBefore: endBeforeTimestamp
		} = options;

		console.log("endBefore:" + endBeforeTimestamp.toDate().toLocaleString());

		let q = query(collection(this.firestore, collectionPath));
		
		if (Array.isArray(whereConditions) && whereConditions.length === 3) {
			q = query(q, where(whereConditions[0], whereConditions[1], whereConditions[2]));
		}
			
		if (Array.isArray(orderByField)) {
			orderByField.forEach(field => {
			q = query(q, orderBy(field, orderDirection));
			});
		} else {
			q = query(q, orderBy(orderByField, orderDirection));
		}		
		
		if (endBeforeTimestamp) {
			q = query(q, where(orderByField, '<', endBeforeTimestamp));
		}
		
		q = query(q, limit(limitCount));

		try {
			const querySnapshot = await getDocs(q);
			return querySnapshot;
		} catch (error) {
			console.error('Error getting more documents:', error);
			throw error;
		}
	}
	deleteDocument(collectionPath, docId) {
		const data = {
			updatetime: new Date(),
		};
		this.updateDocument(collectionPath, docId, data)
		.then(() => {
			setTimeout(() => {
				const docRef = doc(this.firestore, collectionPath, docId);
				deleteDoc(docRef);
			}, 1000);
		})
		.catch((error) => {
		});
	}
	getServerTimestamp() {
		return serverTimestamp();
	}

	/* firestoreのルール
    match /users/{uid} {
		allow list: if false;
		allow get: if request.auth != null;
		allow write: if(request.auth.uid == uid);
    }
    match /users/{uid}/setting/{document=**} {
		allow read, write: if(request.auth.uid == uid);
    }*/

	// /users/{uid}のドキュメントを取得する関数
	async getUserDocument() {
		try {
			const user = this.auth.currentUser;
			if (!user) {
				throw new Error('No user is signed in');
			}
			const uid = user.uid;
			const docRef = doc(this.firestore, 'users', uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			} else {
				return null;
			}
		} catch (error) {
			console.error('Error getting user document:', error);
			throw error;
		}
	}

	// /users/{uid}にドキュメントを書き込む関数
	async setUserDocument(name, color ,iconurl) {
		try {
			const user = this.auth.currentUser;
			if (!user) {
				throw new Error('No user is signed in');
			}
			const uid = user.uid;
			const docRef = doc(this.firestore, 'users', uid);
			await setDoc(docRef, { name, color,iconurl });
			this.logEvent('user_document_set', { uid });
		} catch (error) {
			console.error('Error setting user document:', error);
			this.logEvent('user_document_set_error', { error_message: error.message });
			throw error;
		}
	}

	async setUserDocumentWithData(data) {
		try {
			const user = this.auth.currentUser;
			if (!user) {
				throw new Error('No user is signed in');
			}
			const uid = user.uid;
			const docRef = doc(this.firestore, 'users', uid);
			await setDoc(docRef, data);
			this.logEvent('user_document_set', { uid });
		} catch (error) {
			console.error('Error setting user document:', error);
			this.logEvent('user_document_set_error', { error_message: error.message });
			throw error;
		}
	}

	async getProjectDocument(ownerUid,uuid) {
		try {
			const user = this.auth.currentUser;
			if (!user) {
				throw new Error('No user is signed in');
			}
			const docRef = doc(this.firestore, `/userdata/${ownerUid}/projects`, uuid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				return docSnap.data();
			} else {
				return null;
			}
		} catch (error) {
			console.error('Error getting project document:', error);
			throw error;
		}
	}

	async getIconData(uid,name,color) {
		if(this._userIcons[uid]) {
			return this._userIcons[uid];
		}

		const iconPath = `/userdata/${uid}/icon.jpg`;
		const fileRef = ref(this.storage, iconPath);

		try {
			const url = await getDownloadURL(fileRef);
			this._userIconUrls[uid] = url;
			CommonUtils.makeCircleIcon(url, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
				this._userIcons[uid] = dataURL;
				return dataURL;
			});
		} catch (error) {
			if (error.code === 'storage/object-not-found') {
				CommonUtils.makeCircleIcon(null, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
					this._userIcons[uid] = dataURL;
					return dataURL;
				});
			}
		}
	}

	async checkIconfileAndSave(uid, photoURL,name,color,elementId) {
		if(this._userIcons[uid]) {
			if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = this._userIcons[uid];
		}

		const functionUrl = 'https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/fetchImage';
		const iconPath = `/userdata/${uid}/icon.jpg`;
		const fileRef = ref(this.storage, iconPath);

		try {
			const url = await getDownloadURL(fileRef);
			this._userIconUrls[uid] = url;
			CommonUtils.makeCircleIcon(url, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
				this._userIcons[uid] = dataURL;
				if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = dataURL;
			});
		} catch (error) {
			if (error.code === 'storage/object-not-found') {
				if (!photoURL || typeof photoURL === 'undefined') {
					CommonUtils.makeCircleIcon(null, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
						this._userIcons[uid] = dataURL;
						if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = dataURL;
					});
					return;
				}
				if(photoURL) {
					try {
						const response = await fetch(`${functionUrl}?url=${encodeURIComponent(photoURL)}`);
						if (!response.ok) {
							throw new Error('Network response was not ok.');
						}
						const blob = await response.blob();
						if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = URL.createObjectURL(blob);
						this._myIconBlob = blob;
						const uploadedUrl = await this.uploadImage(blob, iconPath);
						this._userIconUrls[uid] = uploadedUrl;

						await this.saveIconUrl(uid,uploadedUrl);
						CommonUtils.makeCircleIcon(uploadedUrl, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
							this._userIcons[uid] = dataURL;
							if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = dataURL;
						});
					} catch (fetchError) {
						console.error('Error fetching image:', fetchError);
						CommonUtils.makeCircleIcon(null, name, 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[color]), (dataURL) => {
							this._userIcons[uid] = dataURL;
							if(elementId && document.getElementById(elementId)) document.getElementById(elementId).src = dataURL;
						});
					}
				}
			} else {
				console.log('Error:', error);
			}
		}
	}

	setIcon(uid,dataURL) {
		this._userIcons[uid] = dataURL;
	}

	async uploadImage(blob, path) {
		const imageRef = ref(this.storage, path);
		try {
			const snapshot = await uploadBytes(imageRef, blob);
			const url = await getDownloadURL(snapshot.ref);
			console.log("Download URL:", url);
			return url;
		} catch (error) {
			console.error('Upload failed:', error);
			throw error;
		}
	}
	async saveIconUrl(uid,iconUrl) {
		if (!iconUrl || (typeof iconUrl == 'undefined')) return;
		const docRef = this.firestore.collection('names').doc(uid);
		const data = {
			iconurl: iconUrl,
		};
		// データを書き込む
		docRef.update(data).then(() => {
		})
			.catch((error) => {
				console.error("Error: ", error);
			});
	}


	async writeProjectDataToSearchIndex(title,memo,tag,owneruid,ownername,uuid,thumbnailUrl = null,lng = null,lat = null,zoom = null) {
		const collectionPath = `searchindex`;
		const documentId = owneruid + '_' + uuid;

		const keyArray = [title, memo,ownername].concat(tag || []);

		const data = {
			title,
			ownername,
			owneruid,
			uuid,
			memo,
			lng,lat,zoom,
			tags:tag,
			thumbnailUrl:thumbnailUrl || null,
			keys:keyArray,
			updatetime: new Date(),
		};

		try {
			await this.setDocument(collectionPath, documentId, data);
			console.log('writeProjectDataToSearchIndex success!');
		} catch (error) {
			console.error('Failed to writeProjectDataToSearchIndex:', error);
		}
	}

	async deleteSearchIndex(owneruid,uuid) {
		const collectionPath = `searchindex`;
		const docId = owneruid + '_' + uuid;

		try {
			const docRef = doc(this.firestore, collectionPath, docId);
			await deleteDoc(docRef);
			console.log('deleteSearchIndex success!');
		} catch (error) {
			console.error('Failed to deleteSearchIndex:', error);
		}
	}


	/*async searchProjet(key,limitCount,endBeforeTimestamp) {
		let q = query(collection(this.firestore, 'searchindex'), orderBy('updatetime', 'desc'));
		q = query(q, where('keys', 'array-contains', key));

		if (endBeforeTimestamp) {
			q = query(q, where('updatetime', '<', endBeforeTimestamp));
		}
		q = query(q, limit(limitCount));

		try {
			const querySnapshot = await getDocs(q);
			return querySnapshot;
		} catch (error) {
			console.error('Error getting more documents:', error);
			throw error;
		}
	}*/

	async updateLayerSetting(ownerUid,projectId,overlay,map) {
		const collectionPath = `userdata/${ownerUid}/projects/`;
		const data = {
			overlay: overlay,
			basemap: map,
			updatetime: new Date(),
		};
		this.updateDocument(collectionPath, projectId, data)
			.then(() => {
				console.log('updateLayerSetting successfully written!');
			})
			.catch((error) => {
				console.error('Failed to write updateLayerSetting:', error);
			});		
	}



	async searchProject(key, limitCount, endBeforeTimestamp) {
		let q = query(collection(this.firestore, 'searchindex'), orderBy('updatetime', 'desc'));
		
		if (key.trim() !== '') {
			q = query(q, where('keys', 'array-contains', key));
		}

		if (endBeforeTimestamp) {
			q = query(q, where('updatetime', '<', endBeforeTimestamp));
		}
		q = query(q, limit(limitCount));

		try {
			const querySnapshot = await getDocs(q);
			return querySnapshot;
		} catch (error) {
			console.error('Error getting more documents:', error);
			throw error;
		}
	}


	async deleteProject(projectId, OwnerUid, onSuccess, onError) {
		const projectDocRef = doc(this.firestore, `userdata/${OwnerUid}/projects/`, projectId);
		const itemsCollectionRef = collection(this.firestore, `userdata/${OwnerUid}/projects/${projectId}/items`);
		const filesPath = `/files/${OwnerUid}/${projectId}/`;

		try {
			// itemsコレクションのすべてのドキュメントを削除
			const itemsSnapshot = await getDocs(itemsCollectionRef);
			const deleteOps = itemsSnapshot.docs.map(doc => deleteDoc(doc.ref));
			await Promise.all(deleteOps);

			// プロジェクトドキュメントを削除
			await deleteDoc(projectDocRef);

			// Storageからファイルを削除
			await this.deleteFolder(filesPath);

			// 検索インデックスから削除
			await this.deleteSearchIndex(OwnerUid, projectId);

			console.log('Project deleted successfully');
			onSuccess();
		} catch (error) {
			console.error('Error deleting project:', error);
			onError(error);
		}
	}

	// Storage内のフォルダを削除するヘルパーメソッド
	async deleteFolder(storagePath) {
		const storage = getStorage();
		const folderRef = ref(storage, storagePath);

		try {
			// フォルダ内のファイルとサブフォルダを取得
			const result = await listAll(folderRef);

			// すべてのファイルを削除
			const deletePromises = result.items.map((fileRef) => deleteObject(fileRef));

			// サブフォルダがあれば、その中のファイルも再帰的に削除
			const folderDeletePromises = result.prefixes.map((folderRef) =>
				this.deleteFolder(folderRef.fullPath)
			);

			// すべての削除操作を実行
			await Promise.all([...deletePromises, ...folderDeletePromises]);

			console.log(`フォルダ "${storagePath}" が削除されました。`);
		} catch (error) {
			console.error("フォルダの削除中にエラーが発生しました: ", error);
		}
	}
	async copyProject(projectId,originalOwnerUid,newOwnerUid,newOwnerName,onSuccess,onError) {

		const originalDocRef = doc(this.firestore, `userdata/${originalOwnerUid}/projects/`, projectId);
		const originalCollection = collection(this.firestore, `userdata/${originalOwnerUid}/projects/${projectId}/items`, );

		const copyDocId = CommonUtils.generateUUID();
		const copyDocRef = doc(this.firestore, `userdata/${newOwnerUid}/projects/`, copyDocId);

		try {
			// オリジナルのプロジェクトドキュメントをコピー
			const docSnap = await getDoc(originalDocRef);
			if (docSnap.exists()) {
				const projectData = docSnap.data();
				// 新しいオーナー情報で更新
				projectData.title += '_copy';
				projectData.org_owneruid = projectData.owneruid;
				projectData.org_ownername = projectData.ownername;
				projectData.owneruid = newOwnerUid;
				projectData.ownername = newOwnerName;
				projectData.createtime = new Date();
				projectData.updatetime = new Date();
				await setDoc(copyDocRef, projectData);

				const sourcePath = `/files/${projectData.owneruid}/${projectId}/${projectData.owneruid}/open/`;
				const destinationPath = `/files/${newOwnerUid}/${copyDocId}/${newOwnerUid}/open/`;

				if(projectData.thumbnailUrl) {
					projectData.thumbnailUrl = await this.copyFile(sourcePath + 'screenshot.jpg',destinationPath + 'screenshot.jpg');
				}
				if(projectData.permission > 0 && projectData.issearchable) {
					await this.writeProjectDataToSearchIndex(projectData.title,projectData.memo,projectData.tags,newOwnerUid,newOwnerName,copyDocId,projectData.thumbnailUrl,projectData.lng,projectData.lat,projectData.zoom);
				}

				// オリジナルのアイテムコレクションをコピー
				const querySnapshot = await getDocs(originalCollection);
				const copyPromises = querySnapshot.docs.map(async (doc) => {
					const itemData = doc.data();
/*					itemData.org_owneruid = itemData.owneruid;
					itemData.org_ownername = itemData.ownername;
					itemData.owneruid = newOwnerUid;
					itemData.ownername = newOwnerName;
*/
					//自分のアイテムで添付ファイルがある場合はコピーする
					///files/projectData.owneruid/projectId/itemData.owneruid/open
					if(itemData.owneruid === newOwnerUid) {
//						const sourcePath = `/files/${projectData.owneruid}/${projectId}/${itemData.owneruid}/open/`;
//						const destinationPath = `/files/${newOwnerUid}/${copyDocId}/${newOwnerUid}/open/`;
						if(itemData.type === 5) {//写真　fileurl　iconurl　filename"写真 2024-08-24 11 00 47.jpg"　iconurlはfilename+.png
							let fileName = itemData.fileName;
							if(!fileName || fileName === undefined) {
								//開発初期にfilenameとfileNameが混在してたのでチェックする
								fileName = itemData.filename;
								itemData.fileName = fileName;
							}
							if(fileName) {
								const fileurl = await this.copyFile(sourcePath + fileName,destinationPath + fileName);
								itemData.fileurl = fileurl;
								const iconurl = await this.copyFile(sourcePath + fileName + '.png',destinationPath + fileName + '.png');
								itemData.iconurl = iconurl;
							}
						}
						else if(itemData.type === 4) {//ファイル　fileurl
							let fileName = itemData.fileName;
							if(!fileName || fileName === undefined) {
								//開発初期にfilenameとfileNameが混在してたのでチェックする
								fileName = itemData.filename;
								itemData.fileName = fileName;
							}
							if(fileName) {
								const fileurl = await this.copyFile(sourcePath + fileName,destinationPath + fileName);
								itemData.fileurl = fileurl;
							}
						}
						else if(itemData.type === 2) {//手書き　geojsonUrl drawing_uid uid.geojson
							const fileName = 'drawing_' + newOwnerUid + '.geojson';
							const geojsonUrl = await this.copyFile(sourcePath + fileName,destinationPath + fileName);
							itemData.geojsonUrl = geojsonUrl;
						}
						else if(itemData.type === 3) {//図形　geojsonUrl uid.geojson shape_uid
							const fileName = 'shape_' + newOwnerUid + '.geojson';
							const geojsonUrl = await this.copyFile(sourcePath + fileName,destinationPath + fileName);
							itemData.geojsonUrl = geojsonUrl;
						}
					}

					itemData.createtime = new Date();
					itemData.updatetime = new Date();
					await this.setDocument(`userdata/${newOwnerUid}/projects/${copyDocId}/items/`, doc.id, itemData);
				});

				await Promise.all(copyPromises);

				console.log('Project copied successfully');
				onSuccess(projectData,copyDocId);
				return true;
			} else {
				onError('Original project does not exist');
			}
		} catch (error) {
			console.error('Error copying project:', error);
			onError('Error copying project:', error);
			throw error;
		}

	}
	


}

export default FirebaseManager;