import { CommonUtils } from "./CommonUtils";

class ElevationService {
		constructor() {
			this.terrariumBaseUrl = "https://s3.amazonaws.com/elevation-tiles-prod/terrarium";
			this.gsiBaseUrl = "https://cyberjapandata.gsi.go.jp/xyz/dem_png";
			this.gsiBaseUrl5a = "https://cyberjapandata.gsi.go.jp/xyz/dem5a_png";
			this.dbName = 'ElevationTileCache';
			this.storeName = 'tiles';
			this.db = null;
			this.initDB();
		}

		async initDB() {
			return new Promise((resolve, reject) => {
				const request = indexedDB.open(this.dbName, 1);

				request.onerror = (event) => {
					console.error("IndexedDB error:", event.target.error);
					reject("Could not open IndexedDB");
				};

				request.onsuccess = (event) => {
					this.db = event.target.result;
					resolve();
				};

				request.onupgradeneeded = (event) => {
					const db = event.target.result;
					db.createObjectStore(this.storeName);
				};
			});
		}

		async getElevation(lat, lon, zoom = 14) {
			if(zoom > 14) zoom = 14;
//			console.log(`Fetching elevation for lat: ${lat}, lon: ${lon}, zoom: ${zoom}`);
			const isJapan = this.isInJapan(lat, lon);
			try {
				if (!this.db) {
					await this.initDB();
				}

				const { z, x, y } = this.latLonToTile(lat, lon, zoom);
//				console.log(`Tile coordinates: z: ${z}, x: ${x}, y: ${y}`);

				const tileKey = `${isJapan ? 'gsi' : 'terrarium'}_${z}/${x}/${y}`;
				
				let imageData = await this.getTileFromCache(tileKey);
				if (!imageData) {
//					console.log('Tile not in cache, fetching from server');
					imageData = await this.fetchAndDecodeTile(z, x, y, isJapan);
					await this.saveTileToCache(tileKey, imageData);
				} else {
//					console.log('Tile found in cache');
				}
	//this.visualizeImageData(imageData);
				const elevation = this.getElevationFromTile(imageData, lat, lon, zoom, isJapan);
				if (elevation !== null) {
					return elevation.toFixed(0);
				}


//				console.log(`Calculated elevation: ${elevation}`);
				return 0;
			} catch (error) {
				console.error('Error in getElevation:', error);
				const elevationValue = this.isInJapan(lat, lon) ? await CommonUtils.getAltitudeByKokudo(lat, lon) : await CommonUtils.getAltitudeByGoogle(lat, lon);
				if (elevationValue !== null) {
					return elevationValue;
				}

				throw new Error('Failed to get elevation data');
			}
		}

		async visualizeImageData(imageData) {
			const canvas = document.createElement('canvas');
			canvas.width = 256;
			canvas.height = 256;
			const ctx = canvas.getContext('2d');

			const newImageData = ctx.createImageData(256, 256);
			newImageData.data.set(imageData);

			ctx.putImageData(newImageData, 0, 0);

			document.body.appendChild(canvas);
		}

		async getTileFromCache(tileKey) {
			return new Promise((resolve, reject) => {
				const transaction = this.db.transaction([this.storeName], 'readonly');
				const store = transaction.objectStore(this.storeName);
				const request = store.get(tileKey);

				request.onerror = () => {
					reject("Error fetching from cache");
				};

				request.onsuccess = (event) => {
					resolve(event.target.result);
				};
			});
		}

		async saveTileToCache(tileKey, imageData) {
			return new Promise((resolve, reject) => {
				const transaction = this.db.transaction([this.storeName], 'readwrite');
				const store = transaction.objectStore(this.storeName);
				const request = store.put(imageData, tileKey);

				request.onerror = () => {
					reject("Error saving to cache");
				};

				request.onsuccess = () => {
					resolve();
				};
			});
		}

		latLonToTile(lat, lon, zoom) {
			const z = zoom;
			const n = Math.pow(2, z);
			const x = Math.floor((lon + 180) / 360 * n);
			const y = Math.floor(
				(1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * n
			);
			return { z, x, y };
		}

		async fetchAndDecodeTile(z, x, y, isJapan) {
			try {
				const baseUrl = isJapan ? this.gsiBaseUrl5a : this.terrariumBaseUrl;
				let url = `${baseUrl}/${z}/${x}/${y}.png`;
//				console.log(`fetchAndDecodeTile:${url}`);
				let response = await fetch(url);
				if (!response.ok) {
					if(isJapan) {
						url = `${this.gsiBaseUrl}/${z}/${x}/${y}.png`;
//						console.log(`fetchAndDecodeTile:${url}`);
						response = await fetch(url);
						if (!response.ok) {
							throw new Error(`HTTP error! status: ${response.status}`);
						}
					}
					else {
						throw new Error(`HTTP error! status: ${response.status}`);
					}
				}
				const blob = await response.blob();
				const imageData = await this.decodePNG(blob);
				return imageData;
			} catch (error) {
				console.error('Error fetching or decoding tile:', error);
				throw new Error('Failed to fetch or decode tile from server');
			}
		}

		async decodePNG(blob) {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = async () => {
					try {
						let canvas, ctx;

						if (typeof OffscreenCanvas !== 'undefined') {
							canvas = new OffscreenCanvas(img.width, img.height);
							ctx = canvas.getContext('2d');
						} else {
							canvas = document.createElement('canvas');
							canvas.width = img.width;
							canvas.height = img.height;
							ctx = canvas.getContext('2d');
						}

						ctx.drawImage(img, 0, 0);
						const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
						resolve(imageData);
					} catch (e) {
						reject(e);
					}
				};
				img.onerror = (e) => {
					reject(e);
				};
				img.src = URL.createObjectURL(blob);
			});
		}

		getElevationFromTile(imageData, lat, lon, zoom, isJapan) {
//			console.log(`Calculating elevation for lat: ${lat}, lon: ${lon}, zoom: ${zoom}, using ${isJapan ? 'GSI' : 'Terrarium'} encoding`);

			const { z, x, y } = this.latLonToTile(lat, lon, zoom);
//			console.log(`Tile coordinates: z: ${z}, x: ${x}, y: ${y}`);

			const pixelX = (lon - this.tile2lon(x, z)) / (this.tile2lon(x + 1, z) - this.tile2lon(x, z)) * 256;
			const pixelY = (this.tile2lat(y, z) - lat) / (this.tile2lat(y, z) - this.tile2lat(y + 1, z)) * 256;
//			console.log(`Pixel coordinates within tile: x: ${pixelX}, y: ${pixelY}`);

			const px = Math.floor(pixelX);
			const py = Math.floor(pixelY);
//			console.log(`Nearest integer pixel coordinates: x: ${px}, y: ${py}`);

			const idx = (py * 256 + px) * 4;
			const r = imageData[idx];
			const g = imageData[idx + 1];
			const b = imageData[idx + 2];
//			console.log(`RGB values at pixel: R: ${r}, G: ${g}, B: ${b}`);

			let elevation;
			if (isJapan) {
				// GSI encoding: R * 2^16 + G * 2^8 + B
				const x = r * Math.pow(2, 16) + g * Math.pow(2, 8) + b;
				
				if (x < Math.pow(2, 23)) {
					elevation = x * 0.01;  // Convert to meters (accuracy: centimeter)
				} else if (x === Math.pow(2, 23)) {
					elevation = null;  // No data
				} else {
					elevation = (x - Math.pow(2, 24)) * 0.01;  // Negative values
				}
			} else {
				// Terrarium encoding: (R * 256 + G + B / 256) - 32768
				elevation = (r * 256 + g + b / 256) - 32768;
			}

//			console.log(`Calculated elevation: ${elevation !== null ? elevation + ' meters' : 'No data'}`);
			return elevation;
		}

		tile2lon(x, z) {
			return x / Math.pow(2, z) * 360 - 180;
		}

		tile2lat(y, z) {
			const n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
			return 180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
		}

		isInJapan(lat, lon) {
			// Approximate bounding box for Japan
			return (lat >= 20 && lat <= 46 && lon >= 122 && lon <= 154);
		}
}

// インスタンスを作成
const elevationServiceInstance = new ElevationService();

// 作成したインスタンスをエクスポート
export default elevationServiceInstance;