import React, { useState,useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';
const translator = new Translator(resources);

const EditProjectPopup = ({ fm,map,parent,onClose,myName, myColor,data,docId }) => {
	const t = (key) => translator.t(key);
	const [title, setTitle] = useState('');
	const [memo, setMemo] = useState('');
	const [tags, setTags] = useState('');
	const [scope, setScope] = useState('0');
	const [genre, setGenre] = useState('-1');
//	const [baseMap, setBaseMap] = useState('0');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSearchable, setIsSearchable] = useState(false);
	const [isCopyable, setIsCopyable] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		if (data) {
			setTitle(data.title || '');
			setMemo(data.memo || '');
			setTags(data.tags ? data.tags.join(', ') : '');
			setScope(data.permission?.toString() || '0');
			setGenre(data.genre?.toString() || '0');
//			setBaseMap(data.basemap?.toString() || '0');
			setIsSearchable(!!data.issearchable);
			setIsCopyable(!!data.iscopyable);
		}
	}, [data]);

	useEffect(() => {
		if (scope === '0') {
			setIsSearchable(false);
			setIsCopyable(false);
		}
		else if (data) {
			if(data.issearchable) setIsSearchable(true);
			if(data.iscopyable) setIsCopyable(true);
		}

	}, [scope, data]);

	const handleCreateThumbnail = async () => {
		saveDataAndClose(false);
		if(parent) parent.makeThumbnail(title);
	}

	const handleDeletePrpject = async () => {
		if(parent) parent.deleteProject(title);
	}

	const handleSubmit = async () => {
		saveDataAndClose(true);
	}

	const saveDataAndClose = async (isClose) => {
		if (title.length <= 0) {
			setError(t('Enter the title'));
			return;
		}
		if (genre === '-1') {
			setError(t('Please select a genre'));
			return;
		}

		setIsSubmitting(true);
		const collectionPath = `userdata/${data.owneruid}/projects/`;

		// タグを配列に変換
		let tagsString = tags;
		tagsString = tagsString.replace(/[#＃、　 ]/g, ",").replace(/,+/g, ",").replace(/^,|,$/g, "");
		const tagsArray = tagsString.split(',').map(tag => tag.trim()).filter(tag => tag !== '');

		const center = map.getCenter();
		const zoomLevel = map.getZoom();
		const permission = parseInt(scope, 10);
		const firedata = {
			title,
			memo,
			tags: tagsArray,
			ownername: myName,
			ownercolor: myColor,
			owneruid: data.owneruid,
			isfavorite: data.isfavorite || false,
			genre: genre,
			lng:center.lng,
			lat:center.lat,
			zoom:zoomLevel,
			permission: permission,
			issearchable: isSearchable,
			iscopyable: isCopyable,
			thumbnailUrl:parent.thumbnailUrl || null,
			createtime: data.createtime || new Date(),
			updatetime: new Date(),
			language:navigator.language,
		};

		try {
			await fm.updateDocument(collectionPath, docId, firedata);
	
			if(permission > 0 && isSearchable) {
				await fm.writeProjectDataToSearchIndex(title,memo,tagsArray,data.owneruid,myName,docId,parent.thumbnailUrl,center.lng,center.lat,zoomLevel);
			}
			else {
				await fm.deleteSearchIndex(data.owneruid,docId);
			}


			console.log('Document successfully written!');
		} catch (error) {
			console.error('Failed to write document:', error);
		}
		setIsSubmitting(false);
		if(isClose) onClose();
	};
/*
				<label>
					{t('Base Map')}:
					<select value={baseMap} onChange={(e) => setBaseMap(e.target.value)} disabled={isSubmitting}>
						<option value="0">{t('kokudo')}</option>
						<option value="1">{t('osm')}</option>
						<option value="2">{t('otm')}</option>
					</select>
				</label>
 */
	return (
		<div id="thumbnailPopup" className="popup">
			<span className="close_button" onClick={onClose}>&times;</span>
			<div className="popup-inner">
				<h2>{t('edit_projects')}</h2>
				{error && <div className="error-message" style={{color: 'red'}}>{error}</div>}
				<label>
					{t('Title')}:
					<input type="text" value={title} onChange={(e) => setTitle(e.target.value)} disabled={isSubmitting} />
				</label>
				<label>
					{t('Note')}:
					<textarea value={memo} onChange={(e) => setMemo(e.target.value)} disabled={isSubmitting} />
				</label>
				<label>
					{t('Shared range')}:
					<select value={scope} onChange={(e) => setScope(e.target.value)} disabled={isSubmitting}>
						<option value="0">{t('range_private')}</option>
						<option value="1">{t('range_vieweable')}</option>
						<option value="2">{t('range_Joint_editing')}</option>
					</select>
				</label>
				<label>
					{t('Genre')}:
					<select value={genre} onChange={(e) => setGenre(e.target.value)} disabled={isSubmitting}>
						<option value="-1">{t('Select a genre')}</option>
						{[...Array(20)].map((_, index) => (
							<option key={index} value={index.toString()}>
								{t(`Genre_${(index).toString().padStart(2, '0')}`)}
							</option>
						))}
					</select>
				</label>
				<label>
					{t('Tags')}:
					<input 
						type="text" 
						value={tags} 
						onChange={(e) => setTags(e.target.value)} 
						disabled={isSubmitting}
						placeholder={t('Enter tags separated by spaces or commas')}
					/>
				</label>
				<div className="checkbox-wrapper">
					<div className="checkbox-container">
						<input
							type="checkbox"
							id="searchableCheckbox"
							checked={isSearchable}
							onChange={(e) => setIsSearchable(e.target.checked)}
							disabled={isSubmitting || scope === '0'}
						/>
						<label htmlFor="searchableCheckbox">{t('Searchable')}</label>
						<input
							type="checkbox"
							id="copyableCheckbox"
							checked={isCopyable}
							onChange={(e) => setIsCopyable(e.target.checked)}
							disabled={isSubmitting || scope === '0'}
						/>
						<label htmlFor="copyableCheckbox">{t('Copyable')}</label>
					</div>
				</div>
				<div >
					<button className='button_r' onClick={handleDeletePrpject} disabled={isSubmitting}>{t('delete')}</button>
					<button id="button_thumbnail" onClick={handleCreateThumbnail}>{t('Create thumbnail')}</button>
				</div>
				<button className='button_b' onClick={onClose} disabled={isSubmitting}>{t('Cancel')}</button>
				<button className='button_y' id="buttonMakeProject" onClick={handleSubmit} disabled={isSubmitting}>
					{isSubmitting ? t('Processing...') : t('Save')}
				</button>
			</div>
		</div>
	);
};

export default EditProjectPopup;