// PalettePopup.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable'; // react-draggable をインポート
import './css/popup.css'; // 必要な場合、CSSをインポート
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const colors = [
	'#FF0000', '#00FF00', '#0000FF', '#FFFF00',
	'#FF00FF', '#00FFFF', '#800000', '#008000',
	'#000080', '#808000', '#800080', '#008080',
	'#C0C0C0', '#808080', '#FFFFFF', '#000000'
];

const PalettePopup = ({
	onSelectColor,
	onClose,
	initialLineWidth,
	initialLineColor,
	initialLineOpacity,
	onLineWidthChange,
	onLineOpacityChange
}) => {
	const t = (key) => translator.t(key);
	const [lineWidth, setLineWidth] = useState(initialLineWidth);
	const [lineColor, setLineColor] = useState(initialLineColor);
	const [opacity, setOpacity] = useState(initialLineOpacity);

	const popupRef = useRef(null);

	useEffect(() => {
		if (popupRef.current) {
		const { width, height } = popupRef.current.getBoundingClientRect();
		popupRef.current.style.setProperty('--popup-width', `${width}px`);
		popupRef.current.style.setProperty('--popup-height', `${height}px`);
		}
	}, []);
	
	useEffect(() => {
		const rgb = initialLineColor.slice(0, 7);
		const alpha = Math.round(initialLineOpacity / 100 * 255).toString(16).padStart(2, '0');
		const newColor = `${rgb}${alpha}`;
		setLineColor(newColor);
	}, [initialLineColor, initialLineOpacity]);

	const handleLineWidthChange = (event) => {
		const newLineWidth = Number(event.target.value);
		setLineWidth(newLineWidth);
		onLineWidthChange(newLineWidth);
	};

	const handleOpacityChange = (event) => {
		const newOpacity = Number(event.target.value);
		setOpacity(newOpacity);

		const rgb = lineColor.slice(0, 7);
		const alpha = Math.round(newOpacity / 100 * 255).toString(16).padStart(2, '0');
		const newColor = `${rgb}${alpha}`;
		setLineColor(newColor);
		onLineOpacityChange(newColor, newOpacity);
	};

	const updateColor = (color, newOpacity) => {
		const rgb = color.slice(0, 7);
		const alpha = Math.round(newOpacity / 100 * 255).toString(16).padStart(2, '0');
		const newColor = `${rgb}${alpha}`;
		setLineColor(newColor);

		onSelectColor(newColor, newOpacity);
	};

	const handleColorSelect = (color) => {
		updateColor(color, opacity);
	};

	const handleClose = () => {
		onClose();
	};

	const centerPopup = useCallback(() => {
		if (popupRef.current) {
		const rect = popupRef.current.getBoundingClientRect();
		const x = window.innerWidth / 2 - rect.width / 2;
		const y = window.innerHeight / 2 - rect.height / 2;
		return { x, y };
		}
		return { x: 0, y: 0 };
	}, []);
	
	return (
		<Draggable handle=".popup-header">
			<div
				className="popup palette_popup" ref={popupRef}
				// ドラッグ中のカーソルは Draggable が管理するため削除
				// style={{ position: 'absolute', cursor: isDragging ? 'grabbing' : 'default' }}
			>
				<style jsx="true">{`
					.color-grid {
						display: grid;
						grid-template-columns: repeat(4, 1fr);
						gap: 5px;
						margin-top: 5px;
					}

					.color-box {
						width: 50px;
						height: 50px;
						cursor: pointer;
					}

					.color-banner {
						width: 100%;
						height: 25px;
						cursor: pointer;
					}
				`}</style>

				<div className="popup-inner">
					<div className="popup-header">
						<h2>{t('Select a Color')}</h2>
						<span className="close_button" onClick={handleClose}>&times;</span>
					</div>
					<label style={{ textAlign: 'center' }}>
						{t('line_width')} {lineWidth}:
						<input
							type="range"
							min="1"
							max="8"
							value={lineWidth}
							onChange={handleLineWidthChange}
						/>
					</label>
					<label style={{ textAlign: 'center' }}>
						{t('Opacity')} {opacity.toFixed(0)}%:
						<input
							type="range"
							min="10"
							max="100"
							value={opacity}
							onChange={handleOpacityChange}
							className="opacity-slider"
						/>
					</label>
					<label style={{ textAlign: 'center' }}>
						{t('Current color')} {lineColor}:
						<div className='color-banner' style={{ backgroundColor: lineColor }}></div>
					</label>
					<label style={{ textAlign: 'center' }}>
						{t('Choose from the pallet')}:
						<div className="color-grid">
							{colors.map((color, index) => (
								<div
									key={index}
									className="color-box"
									style={{ backgroundColor: color }}
									onClick={() => handleColorSelect(color)}>
								</div>
							))}
						</div>
					</label>
					<button className='button_y' onClick={handleClose} style={{ marginTop: '20px' }}>{t('Close')}</button>
				</div>
			</div>
		</Draggable>
	);
};

export default PalettePopup;
