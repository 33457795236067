import React, { useState, useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';

const translator = new Translator(resources);

const UserSettingsPopup = ({ fm, onSave, onClose, initialSettings }) => {
	const t = (key) => translator.t(key);
	const [name, setName] = useState(initialSettings.name || '');
	const [color, setColor] = useState(initialSettings.color || 0);
	const [handedness, setHandedness] = useState(initialSettings.handedness || 0);
	const [overlay, setOverlay] = useState(initialSettings.defaultOverlay || 'slopemap');
	const [baseMap, setBaseMap] = useState(initialSettings.defaultBaseMap || 'pale');
	const [scope, setScope] = useState(initialSettings.defaultScope || '1');
	const [genre, setGenre] = useState(initialSettings.defaultGenre || '0');
	const [isSearchable, setIsSearchable] = useState(initialSettings.defaultIsSearchable !== false);
	const [isCopyable, setIsCopyable] = useState(initialSettings.defaultIsCopyable !== false);
	const [error, setError] = useState('');

	useEffect(() => {
		console.log(initialSettings);
	}, []);

	useEffect(() => {
		if (scope === '0') {
			setIsSearchable(false);
			setIsCopyable(false);
		}
	}, [scope]);

	const handleSubmit = async () => {
		if (name.length <= 0) {
			setError(t('Enter your name'));
			return;
		}

		setError('');
		const data = {
			name,
			color: parseInt(color, 10),
			handedness: handedness,
			defaultOverlay: overlay,
			defaultBaseMap: baseMap,
			defaultScope: parseInt(scope, 10),
			defaultGenre: parseInt(genre, 10),
			defaultIsSearchable: isSearchable,
			defaultIsCopyable: isCopyable,
			updatetime:new Date(),
		};

		try {
			await fm.setUserDocumentWithData(data);
			console.log('User settings successfully updated!');
			onSave();
			onClose();
		} catch (error) {
			console.error('Failed to update user settings:', error);
			setError(t('Failed to update settings'));
		}
	};

	/*const handlePermanentAccount = async () => {
		try {
			await fm.linkAnonymousAccountWithGoogle();
			setIsAnonymous(false);
			console.log('Account made permanent successfully');
			// 成功メッセージを表示するなどの追加の処理
		} catch (error) {
			console.error('Failed to make account permanent:', error);
			setError(t('Failed to make account permanent'));
		}
	};*/
	
	return (
		<div className="popup">
		<span className="close_button" onClick={onClose}>&times;</span>
		<div className="popup-inner">
			<h2>{t('User Settings')}</h2>
			{error && <div className="error-message" style={{color: 'red'}}>{error}</div>}
			<h3>{t('Personal Settings')}</h3>
			<label>
			{t('Name')}:
			<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
			</label>
			<label>
			{t('Theme Color')}:
			<select 
				value={color} 
				onChange={(e) => setColor(e.target.value)}
				style={{backgroundColor: CommonUtils.numberToHexColor(CommonUtils.colors[color])}}
			>
				{CommonUtils.colors.map((colorValue, index) => (
				<option key={index} value={index} style={{backgroundColor: CommonUtils.numberToHexColor(colorValue)}}>
					{CommonUtils.numberToHexColor(colorValue)}
				</option>
				))}
			</select>
			</label>
			<div className="handedness-control">
			{t('Handedness')}:
			<label>
				<input
				type="radio"
				value="0"
				checked={handedness === 0}
				onChange={() => setHandedness(0)}
				/>
				{t('Right-handed')}
			</label>
			<label>
				<input
				type="radio"
				value="1"
				checked={handedness === 1}
				onChange={() => setHandedness(1)}
				/>
				{t('Left-handed')}
			</label>
			</div>

			<h3>{t('Default Map Creation Settings')}</h3>
			<label>
			{t('Overlay')}:
			<select value={overlay} onChange={(e) => setOverlay(e.target.value)}>
				{Object.entries(CommonUtils.overlays).map(([key, value]) => (
				value.op < 100 && (
					<option key={key} value={key}>
					{value.name}
					</option>
				)
				))}
			</select>
			</label>
			<label>
			{t('Base Map')}:
			<select value={baseMap} onChange={(e) => setBaseMap(e.target.value)}>
				{Object.entries(CommonUtils.maps).map(([key, value]) => (
				value.op === 100 && (
					<option key={key} value={key}>
					{value.name}
					</option>
				)
				))}
			</select>
			</label>
			<label>
			{t('Shared range')}:
			<select value={scope} onChange={(e) => setScope(e.target.value)}>
				<option value="0">{t('range_private')}</option>
				<option value="1">{t('range_vieweable')}</option>
				<option value="2">{t('range_Joint_editing')}</option>
			</select>
			</label>
			<label>
			{t('Genre')}:
			<select value={genre} onChange={(e) => setGenre(e.target.value)}>
				<option value="-1">{t('Select a genre')}</option>
				{[...Array(20)].map((_, index) => (
				<option key={index} value={index.toString()}>
					{t(`Genre_${(index).toString().padStart(2, '0')}`)}
				</option>
				))}
			</select>
			</label>
			<div className="checkbox-wrapper">
			<div className="checkbox-container">
				<input
				type="checkbox"
				id="searchableCheckbox"
				checked={isSearchable}
				onChange={(e) => setIsSearchable(e.target.checked)}
				disabled={scope === '0'}
				/>
				<label htmlFor="searchableCheckbox">{t('Searchable')}</label>
			</div>
			<div className="checkbox-container">
				<input
				type="checkbox"
				id="copyableCheckbox"
				checked={isCopyable}
				onChange={(e) => setIsCopyable(e.target.checked)}
				disabled={scope === '0'}
				/>
				<label htmlFor="copyableCheckbox">{t('Copyable')}</label>
			</div>
			</div>

			<button className='button_b' onClick={onClose}>{t('Cancel')}</button>
			<button className='button_y' onClick={handleSubmit}>{t('Save')}</button>
		</div>
		</div>
	);
};

export default UserSettingsPopup;