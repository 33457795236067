
export class CommonUtils {
	static colors = [
		0xdd5144, 0xf09000, 0xa06b04, 0xb0c000, 0x498702, 0x06cc00, 0x04933d, 0x00d080, 0x028d98, 0x00c1d0,
		0x3f76da, 0xad9fff, 0x985bdf, 0xff71ff, 0xda4ca2, 0xf070c0,
	];


//  { type:'', name: '', url: '', cp:'',mz:,bp:0,op:100,cc:0 },
	static overlays = {
		'null':{ type:'', name: '', url: '', cp:'',ts:256, minz:0, mz:0,bp:0,op:0,cc:0 },
		'relief':{ type:'jp', name: '色別標高図', url: 'https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:5, mz:15,bp:0,op:40,cc:0,proxy:false },
		'hillshademap':{ type:'jp', name: '陰影起伏図', url: 'https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'slopemap':{ type:'jp', name: '傾斜量図', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopemap/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'slopezone1map':{ type:'jp', name: '雪崩傾斜', url: 'https://cyberjapandata.gsi.go.jp/xyz/slopezone1map/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:3 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'afm':{ type:'jp', name: '活断層図', url: 'https://cyberjapandata.gsi.go.jp/xyz/afm/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:11 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'lcmfc2':{ type:'jp', name: '治水地形分類図', url: 'https://cyberjapandata.gsi.go.jp/xyz/lcmfc2/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:11 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'swale':{ type:'jp', name: '明治期の低湿地', url: 'https://cyberjapandata.gsi.go.jp/xyz/swale/{z}/{x}/{y}.png', cp:'国土地理院',ts:256, minz:10 ,mz:16,bp:0,op:40,cc:0,proxy:false },
		'seamless':{ type:'jp', name: 'シームレス地質図', url: 'https://gbank.gsj.jp/seamless/v2/api/1.2/tiles/{z}/{y}/{x}.png', cp:'産業技術総合研究所地質調査総合センター',ts:256, minz:3 ,mz:13,bp:0,op:40,cc:0,proxy:false },
		'landslide':{ type:'jp', name: '地すべり地形分布図日本全国版', url: 'https://www.j-shis.bosai.go.jp/map/xyz/landslide/{z}/{x}/{y}.png', cp:'防災科学技術研究所地震ハザードステーション',ts:256, minz:5 ,mz:15,bp:0,op:40,cc:0,proxy:false },
		'pale':{ type:'jp', name: '淡色地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:40,cc:0,proxy:false },
		'osm':{ type:'osm', name: 'OpenStreetMap', url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:18,bp:0,op:40,cc:0,proxy:false },
	};

	static maps = {
		'pale':{ type:'jp', name: '淡色地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:100,cc:0,proxy:false },
		'std':{ type:'jp', name: '標準地図', url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', cp:'国土地理院',ts:128, minz:5 ,mz:17,bp:0,op:100,cc:0,proxy:false },
		'seamlessphoto':{ type:'jp', name: '全国最新写真', url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg', cp:'国土地理院', ts:256, minz:2 ,mz:18,bp:0,op:100,cc:0,proxy:false },
		'vbm':{ type:'jp', name: '火山基本図', url: 'https://cyberjapandata.gsi.go.jp/xyz/vbm/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'vbmd_bm':{ type:'jp', name: '火山基本図データ', url: 'https://cyberjapandata.gsi.go.jp/xyz/vbmd_bm/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'lake1':{ type:'jp', name: '湖沼図', url: 'https://cyberjapandata.gsi.go.jp/xyz/lake1/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:17,bp:0,op:100,cc:0,proxy:false },
		'lakedata':{ type:'jp', name: '湖沼データ', url: 'https://cyberjapandata.gsi.go.jp/xyz/lakedata/{z}/{x}/{y}.png', cp:'国土地理院', ts:256, minz:11, mz:18,bp:0,op:100,cc:0,proxy:false },
		'osm':{ type:'osm', name: 'OpenStreetMap', url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:18,bp:0,op:100,cc:0,proxy:false },
		'otm':{ type:'osm', name: 'OpenTopoMap', url: 'https://b.tile.opentopomap.org/{z}/{x}/{y}.png', cp:'OpenStreetMap', ts:256, minz:1, mz:18,bp:0,op:100,cc:0,proxy:false },
	};

	delayPromise(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	delay(ms, callback) {
		setTimeout(callback, ms);
	}

	static formatDate(date) {
		return date.toISOString().split('T')[0];
	}

	static isElementInViewport(el) {
		var rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	static formatDateWithIsSec(date,isSec = true) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');
	
		if(isSec)	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		return `${year}-${month}-${day} ${hours}:${minutes}`;
	}

	static formatDateT(now) {
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return CommonUtils.formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
	}


	static formatDateHHMM(date) {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		return `${hours}:${minutes}`;
	}
	

	static capitalizeString(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	static generateRandomId() {
		return Math.random().toString(36).substr(2, 9);
	}

	static isValidEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	static generateUUID() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		);
	}
	static isiPad() {
		const ua = navigator.userAgent;
		return /iPad|Macintosh/i.test(ua) && 'ontouchend' in document;
	}

	static isIOS() {
		const userAgent = CommonUtils.getPlatform().toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	}

	static getPlatform() {
		let platform = 'Unknown Platform';
		if (!navigator || !navigator.userAgent) {
			return platform;
		}
		const userAgent = navigator.userAgent;
		if (userAgent.includes('Android')) {
			platform = 'Android';
		} else if (userAgent.includes('iPhone')) {
			platform = 'iPhone';
		} else if (userAgent.includes('iPad')) {
			platform = 'iPad';
		} else if (userAgent.includes('Win')) {
			platform = 'Windows';
		} else if (userAgent.includes('Mac')) {
			platform = 'Macintosh';
		} else if (userAgent.includes('Linux')) {
			platform = 'Linux';
		}

		return platform;
	}

	static formatString(template, ...values) {
		return template.replace(/{(\d+)}/g, (match, number) => { 
			return typeof values[number - 1] !== 'undefined'
			? values[number - 1]
			: match;
		});
	}

	static convertToHashtags(text) {
		console.log('convertToHashtags:' + text);
		if(!text || text === undefined) return "";
		
		if (typeof text !== 'string') {
			console.warn('Input is not a string, attempting to convert');
			text = String(text);
		}
		const words = text.split(/[,\s]+/).filter(word => word.length > 0);
		const hashtags = words.map(word => `#${word}`);
		return hashtags.join(' ');
	}

	static async getAltitudeByGoogle(lat, lng) {
		const url = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/getAltitude?lat=${lat}&lng=${lng}`;

		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			if (data.status !== 'OK') {
				throw new Error(`Error fetching data: ${data.status}`);
			}
			const altitude = data.results[0].elevation;
			if (altitude && typeof altitude !== 'undefined') return data.results[0].elevation.toFixed(0);
			else return null;
		} catch (error) {
			console.error('Error fetching altitude data:', error);
			return null;
		}
	}

	static async getAltitudeByKokudo(lat, lng) {
		const url = `https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?lon=${lng}&lat=${lat}&outtype=JSON`;
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			if (data.elevation !== undefined) {
				return data.elevation.toFixed(0);
			} else {
				throw new Error('No elevation data found');
			}
		} catch (error) {
			console.error('Error fetching altitude data:', error);
			return CommonUtils.getAltitudeByGoogle(lat, lng);
		}
	}

	//ユーザーアイコン関連

	static makeCircleIcon(imgUrl,name,width,height,lineWidth,color,callback) {
		if(imgUrl) {
			var overlayImage = new Image();
			overlayImage.crossOrigin = "Anonymous";
			overlayImage.onload = function() {
				var canvas = CommonUtils.makeCircleIconBase(overlayImage,name,width,height,lineWidth,color);
				callback(canvas.toDataURL('image/png'));
			};
			overlayImage.src = imgUrl;
		}
		else {
			var canvas = CommonUtils.makeCircleIconBase(null,name,width,height,lineWidth,color);
			callback(canvas.toDataURL('image/png'));
		}
	}
	static makeCircleIconBase(overlayImage,name,width,height,lineWidth,color){
		var canvas = document.createElement('canvas');
		var context = canvas.getContext('2d');

		var radius = Math.min(width, height) / 2;
		canvas.width = width;
		canvas.height = height;

		context.beginPath();
		context.arc(width / 2, height / 2, radius - lineWidth, 0, Math.PI * 2);
		context.strokeStyle = color;
		context.lineWidth = lineWidth;
		context.stroke();

		context.beginPath();
		context.arc(width / 2, height / 2, radius - lineWidth * 1.5, 0, Math.PI * 2);
		context.fillStyle = '#fff';
		context.fill();

		//name = "aa";

		if(overlayImage) {
			const offset = lineWidth * 2;
			context.beginPath();
			context.arc(width / 2, height / 2, radius - lineWidth * 2.5, 0, Math.PI * 2, true);
			context.clip();
			context.drawImage(overlayImage, offset, offset, width - offset,height - offset);
		}
		else if(name){
			let firstChar = name.charAt(0);
			context.fillStyle = color;
			if(name.length > 1 && CommonUtils.areFirstTwoCharsAscii(name)) {
				const offset = lineWidth * 5;
				firstChar = name.substring(0, 2);
				context.font = `bold ${height * 0.7}px Arial`;
				context.fillText(firstChar, offset, height -lineWidth * 4- offset,width - offset);
			}
			else {
				const offset = lineWidth * 6;
				context.font = `bold ${height * 0.65}px Arial`;
				context.fillText(firstChar, offset, height -lineWidth * 3- offset,width - offset);
			}
		}
		return canvas;
	}
	static areFirstTwoCharsAscii(str) {
		return /^[ -~]{2}/.test(str);
	}
	static numberToHexColor(number) {
		if(!number) number = 0;
		return '#' + number.toString(16).padStart(6, '0');
	}

	static reduceArrayToFraction(array, n) {
		if (n <= 0) {
			throw new Error("N must be a positive integer");
		}
		
		const result = [];
		const step = Math.max(1, Math.floor(array.length / n));
		
		for (let i = 0; i < array.length; i += step) {
			result.push(array[i]);
		}
		
		return result;
	}

	static escapeXml(unsafe) {
		return unsafe.replace(/[<>&'"]/g, function (c) {
			switch (c) {
				case '<': return '&lt;';
				case '>': return '&gt;';
				case '&': return '&amp;';
				case "'": return '&apos;';
				case '"': return '&quot;';
				default: return c;
			}
		});
	}

}
